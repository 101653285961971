import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';

export const noAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);

  authService.removeAuthenticatedSession();
  return true;
};
