import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';

/**
 * Guard for validate is authenticated or not, if authenticated will navigate to dashboard
 * @returns {boolean} true if unauthenticated and false if authenticated
 */
export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuthenticated() || !authService.hasUserTwoFAVerified()) {
    router.navigate(['/auth/login']);
    return false;
  }
  return true;
};
